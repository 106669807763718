import { QueryClient } from "@tanstack/react-query";

import { instance } from "@/react/hooks/api";
import {
  createAxiosMutation,
  createAxiosQuery,
} from "@/react/hooks/react-query";
import { User } from "@/react/types/users/user";

interface UpdateUserParams {
  user: User;
  body: {
    email?: string;
    first_name?: string;
    last_name?: string;
    mobile_phone?: string;
    employee_role_id: number | undefined | null;
  };
}

export const useCurrentUserQuery = createAxiosQuery<void, User>(
  "current-user",
  async () => {
    const { data } = await instance().get<User>("/users/me");
    return data;
  },
  {
    // for the purposes of mgmt-api, we are considered logged in IFF window.websocketAccessToken
    // has a value (it is undefined on public pages)
    enabled: !!window.websocketAccessToken,
    // We can safely cache this data forever and manually invalidate the cache when the user edits their profile.
    staleTime: Infinity,
  }
);

const invalidateCurrentUserQuery = (queryClient: QueryClient) => {
  queryClient.invalidateQueries(["current-user"]);
};

export const useEditUserMutation = createAxiosMutation(
  async ({ user, body }: UpdateUserParams) => {
    const { data } = await instance().patch(`/users/${user.id}`, body);
    return data;
  },
  {
    onSettled: (queryClient) => {
      invalidateCurrentUserQuery(queryClient);
    },
  }
);
