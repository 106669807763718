import React from "react";
import { View, StyleSheet } from "react-native";

import Loader from "../Loader";

/**
 *
 * @deprecated please use lower level Skeleton from @smartrent/ui instead
 */
const FullScreenLoader = () => (
  <View style={styles.loaderContainer}>
    <Loader size={100} />
  </View>
);

const styles = StyleSheet.create({
  loaderContainer: {
    width: "100vw",
    height: "100vh",
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment -- position: fixed is not suported by react native, but works on web
    // @ts-ignore
    position: "fixed",
    backgroundColor: "rgba(0, 0, 0, 0.12)",
    top: 0,
    left: 0,
    alignItems: "center",
    justifyContent: "center",
  },
});

export default FullScreenLoader;
