import React, { createContext, useContext, useEffect } from "react";
import * as Sentry from "@sentry/react";

import { useCurrentUserQuery } from "@/react/queries/support-api/current-user";
import { User } from "@/react/types/users/user";

// Do not export the context directly to prevent consumers from accidentally
// getting the default state since it doesn't match the type definition.
const UserContext = createContext<User>(undefined as any);

export const useCurrentUser = () => useContext(UserContext);

export const UserProvider: React.FC = ({ children }) => {
  // we are considered logged in for the purposes of support-api, which this component
  // is talking to IFF window.websocketAccessToken has a value (it is undefined
  // on public pages and in ResWeb)
  const loggedIn = !!window.websocketAccessToken;

  const { data, isLoading, error } = useCurrentUserQuery();

  useEffect(() => {
    if (data?.id) {
      Sentry.setUser({
        id: String(data.id),
        role: data?.role,
        organization_id: String(data?.organization?.id),
      });
    }
  }, [data?.id, data?.role, data?.organization?.id]);

  // block rendering children when the request is loading
  if (isLoading) {
    return null;
  }

  // useApi's initial state has response as null and loading as false. if the user is
  // logged in, we want to block rendering children
  if (!data && !error && loggedIn) {
    return null;
  }

  return (
    <UserContext.Provider value={data as User}>{children}</UserContext.Provider>
  );
};
