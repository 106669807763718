import React, { createContext, useState, useCallback, useContext } from "react";
import { AlertDialogProps, AlertDialog } from "@smartrent/ui";

type ModalProps = Omit<AlertDialogProps, "visible" | "onClose">;

type OpenModal = (modalProps: ModalProps) => Promise<void>;

const modalContext = createContext<OpenModal>(undefined);

export const useModal = () => useContext(modalContext);

interface ModalProviderProps {
  children: React.ReactNode;
}

export const ModalProvider: React.FC<ModalProviderProps> = ({ children }) => {
  const [visible, setVisible] = useState(false);
  const [resolve, setResolve] = useState<() => void>(null);
  const [modalProps, setModalProps] = useState<ModalProps>(undefined);
  const openModal = useCallback<OpenModal>(
    (modalProps) =>
      new Promise((resolve) => {
        setVisible(true);
        setResolve(() => resolve);
        setModalProps({ ...modalProps });
      }),
    [setVisible, setResolve, setModalProps]
  );

  const handleClose = useCallback(() => {
    setVisible(false);
    resolve();
  }, [setVisible, resolve]);

  return (
    <modalContext.Provider value={openModal}>
      {children}
      <AlertDialog onClose={handleClose} visible={visible} {...modalProps} />
    </modalContext.Provider>
  );
};
